/* global $ */
class Nav {
	constructor(options = {}) {
		this.ada = new ADA();
		this.nav = (options.navId ? $("#"+options.navId+"") : $("#nav-main"));
		this.navToggleBtn = (options.navToggleBtnId ? $("#"+options.navToggleBtnId+"") : $("#menu-toggle"));
		this.navDropdown = (options.dropdownClass ? $("#"+options.dropdownClass+"") : $(".drop"));

		this.ada.setADAProps(this.nav.find("a"),false);

		console.log("%c\u2713 %cNav %cModule %cis installed","font-size:2em","font-weight:bold; font-size:1.5em;color: #6f42c1;"," color: #444; font-weight:bold; font-size:1.5em;","font-weight:normal; font-size:1em;");
	}

	showNavDropdown($target) {
		$target.next("ul").toggle(300);
		this.ada.toggleExpandedProp($target);
		this.ada.toggleHiddenProps($target.next("ul").find(">li>a"));
	}
	toggleNav() {
		this.navToggleBtn.toggleClass("is-active");
		this.nav.toggleClass("active");
		$("body").toggleClass("no-scroll");
	}
	hideDropdown() {
		this.navDropdown.removeClass("active");
		this.navDropdown.find("ul").hide(300);
		if (this.navDropdown.find(">a").attr("aria-expanded") === "true") {
			this.ada.toggleExpandedProp(this.navDropdown.find(">a"));
		}
		this.ada.setADAProps($(".drop ul li a"),false);
	}
}